import React from "react"
import { StaticQuery, graphql } from "gatsby"
import RemarkFormatter from "../../components/remark-formatter"

const TailoredSolutionsPageContent = (props) => {
    const { data } = props
    const { edges: posts } = data.allMarkdownRemark

    return (
        <>

            {posts &&
                posts.map(({ node: post }) => (
                    <>
                        <h1>{post.frontmatter.heading}</h1>
                        <RemarkFormatter className="section-text-remarkable" content={post.frontmatter.content} />
                    </>
                ))}

        </>
    )
}

export default () => <StaticQuery
    query={graphql`
        query TailoredSolutionsContentQuery {
            allMarkdownRemark(filter: {frontmatter: {templateKey: { eq: "tailored-solutions-content" }}}) {
                edges {
                node {
                    id
                    frontmatter {
                        heading
                        content
                        templateKey
                    }
                }
                }
            }
        }
   `}
    render={data => (
        <TailoredSolutionsPageContent data={data} />
    )}
/>



//export default AboutUs