import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TailoredSolutionsContentTemplate from "../templates/tailored-solutions/tailored-solutions-content-template"
import Hero03 from '../templates/home/hero-03-template'
import {Helmet} from 'react-helmet'


const TailoredSolutionsPage = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page" data-active-page="page-tailored-solutions" />
      </Helmet>
      <SEO title="Tailored Solutions" />
      {/* <HeroImgSubPage /> */}
      <Hero03 />
      <section className="content-container">
        <TailoredSolutionsContentTemplate />
      </section>
    </Layout>
  </>
)

export default TailoredSolutionsPage
